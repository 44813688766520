import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "card-header"
}
const _hoisted_3 = { class: "card-body position-relative" }
const _hoisted_4 = {
  key: 0,
  class: "position-absolute top-0 w-100 h-100 d-flex start-0",
  style: {"z-index":"999","background-color":"rgba(0,0,0,0.2)"}
}
const _hoisted_5 = { class: "m-auto" }
const _hoisted_6 = {
  key: 1,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_loader = _resolveComponent("b-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isOpacityLoader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_b_loader, { style: {"width":"34px"} }),
              _renderSlot(_ctx.$slots, "opacity-loader")
            ])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "body"),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "footer")
        ]))
      : _createCommentVNode("", true)
  ]))
}