
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'BLoader',
    props: {
        color: {
            type: String as PropType<
                'primary' | 'primary-outline' | 'danger' | 'danger-outline' | 'none' | 'yellow' | 'yellow-outline'
            >,
            default: 'primary',
        },
    },
});
