
import {defineComponent} from "vue";
import BLoader from "@/components/BLoader/BLoader.vue";

export default defineComponent({
    components: {BLoader},
    props: {
        isOpacityLoader: {
            type: Boolean,
            default: false,
        },
    }
})
